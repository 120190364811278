export const BOLETO = 'Boleto';
export const SEPA = 'SEPA';
export const STORAGE_ALERTS_KEY = 'alertsData';
export const CHANGE_PLAN_SUCCESS_FLOW = 'CHANGE_PLAN_SUCCESS_FLOW';
export const OFFICE_HOURS_TITLE_ADD_ON = 'Plus Unlimited Office Hours data';
export const VOICE_ONLY_CONNECTION_PRODUCT_TYPE_ID =
  '147e55f6-8f33-42b0-b868-6750cee05047';

export const PAYMENT_METHOD_NAME = {
  ACH: 'ACH',
  AmericanExpress: 'American Express',
  Boleto: 'Boleto',
  DinersClub: 'Diners Club',
  Discover: 'Discover',
  Elo: 'Elo',
  Hipercard: 'Hipercard',
  MasterCard: 'MasterCard',
  PayPal: 'PayPal',
  Pix: 'Pix',
  SEPA: 'SEPA',
  Visa: 'Visa',
} as const;
